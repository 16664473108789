import { useState } from 'react';
import SvgButton from '@/svg/youtube-button.svg';

const LazyLoadYouTube = ({ videoId, width = '100%', height = '100%' }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const posterUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  const iframeUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1`;

  const handleLoadVideo = () => {
    setIsVideoLoaded(true);
  };

  return (
    <div
      className="lazy-load-youtube__container"
      style={{ position: 'relative', cursor: 'pointer' }}
    >
      {!isVideoLoaded ? (
        <div className="w-full h-full">
          <div
            onClick={handleLoadVideo}
            style={{ width: '68px', height: '48px' }}
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-1"
          >
            <SvgButton />
          </div>
          <img
            src={posterUrl}
            alt="YouTube Video Thumbnail"
            width={width}
            height={height}
            onClick={handleLoadVideo}
            style={{
              objectFit: 'cover',
              display: 'block',
              height: '100%',
              width: '100%',
            }}
          />
        </div>
      ) : (
        <iframe
          width={width}
          height={height}
          src={iframeUrl}
          allowFullScreen
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
        />
      )}
    </div>
  );
};

export default LazyLoadYouTube;
